import React from "react";
import Nav from "../components/nav";

import '../scss/layout.scss';

export default function Layout({ children }) {
  return (
    <>
    <Nav />
    <div className="container main-content" style={{ paddingTop: '75px', paddingBottom: '75px' }}>
      {children}
    </div>
    </>
  )
}

