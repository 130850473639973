import React from 'react';

import Socials from './socials';

import '../scss/nav.scss';

export default function Nav() {
  return (
    <div className="nav">
      <Socials />
      <ul className="submit-link">
        <li><a className="btn btn-primary" href="/submissions">Artist Submissions</a></li>
      </ul>
    </div>
  )
}